.App {
  background: white;
}

body {
  overscroll-behavior-y: none;
  overscroll-behavior-x: none;
  min-height: 100vh;
  min-height: -webkit-fill-available;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  background: white;
}

.hidden {
  display: none !important;
}

.no_padding_top {
  padding-top: 0px !important;
}

.title {
  text-align: center;
  text-decoration: none;
  color: black;
  font-size: 30px;
  font-weight: bold;
  font-family: "SpaceMono", sans-serif;
  height: 32px;
  top: -6px;
  position: relative;
}

.body {
  position: absolute;

  display: flex;
  justify-content: center;
  flex-direction: column;

  width: calc(100% - 310px);
  padding: 45px 40px 40px 270px;
}

@media screen and (min-width: 1280px) and (max-width: 1405px) {
  .body {
    width: calc(100% - 285px);
    padding: 45px 40px 40px 245px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1280px) {
  .body {
    width: calc(100% - 260px);
    padding: 45px 40px 40px 220px;
  }
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
  .body {
    width: calc(100% - 80px);
    padding: 45px 40px 40px 40px;
  }
}

@media screen and (min-width: 0px) and (max-width: 600px) {
  .body {
    width: calc(100% - 40px);
    padding: 45px 20px 20px 20px;
  }
}

@media screen and (min-width: 380px) and (max-width: 460px) {
  .title {
    font-size: 24px;
    top: 0px;
  }
}

@media screen and (min-width: 340px) and (max-width: 380px) {
  .title {
    font-size: 20px;
    top: 0px;
  }

  .hamburger-icon {
    font-size: 18px;
  }
}

@media screen and (min-width: 0px) and (max-width: 340px) {
  .title {
    font-size: 18px;
    top: 0px;
  }

  .hamburger-icon {
    font-size: 16px;
  }
}
