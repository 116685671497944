.contact-container,
.experience-container {
  padding-bottom: 36px;
  font-family: Overpass, sans-serif;
}

.education-container {
  font-family: Overpass, sans-serif;
}

.flex-split {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.about-container {
  width: calc(55% - 22.5px);
  padding-right: 22.5px;
}

.image-container {
  display: flex;
  justify-content: center;

  width: calc(45% - 22.5px);
  padding-left: 22.5px;
}

.profile-image {
  width: 100%;
  padding-top: 18px;
  height: max-content;
  height: intrinsic;
  position: sticky;
  top: 18px;
}

@media screen and (min-width: 0px) and (max-width: 1000px) {
  .flex-split {
    flex-direction: column;
  }

  .about-container {
    width: 100%;
    padding-right: 0px;
  }

  .image-container {
    width: 100%;
    padding-left: 0px;
  }

  .contact-container,
  .experience-container {
    padding-bottom: 0px;
  }
}
