@font-face {
  font-family: SpaceMono;
  font-weight: normal;
  font-style: normal;
  src: url(./fonts/SpaceMono/SpaceMono-Regular.ttf);
}

@font-face {
  font-family: SpaceMono;
  font-weight: bold;
  font-style: normal;
  src: url(./fonts/SpaceMono/SpaceMono-Bold.ttf);
}

@font-face {
  font-family: SpaceMono;
  font-weight: normal;
  font-style: italic;
  src: url(./fonts/SpaceMono/SpaceMono-Italic.ttf);
}

@font-face {
  font-family: SpaceMono;
  font-weight: bold;
  font-style: italic;
  src: url(./fonts/SpaceMono/SpaceMono-BoldItalic.ttf);
}

@font-face {
  font-family: Overpass;
  font-weight: 400;
  font-style: normal;
  src: url(./fonts/Overpass/Overpass-Regular.ttf);
}

@font-face {
  font-family: Overpass;
  font-weight: 700;
  font-style: normal;
  src: url(./fonts/Overpass/Overpass-ExtraBold.ttf);
}

@font-face {
  font-family: PressStart2P;
  font-weight: normal;
  font-style: normal;
  src: url(./fonts/PressStart2P/PressStart2P.ttf);
}
