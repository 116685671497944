.landing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-family: SpaceMono, sans-serif;
  height: 100vh;
  width: 100vw;
}

.landing:hover {
  cursor: inherit;
}

.blue {
  color: #4285f4;
}

.red {
  color: #ea4335;
}

.yellow {
  color: #fbbc05;
}

.green {
  color: #34a853;
}

@media screen and (min-width: 700px) {
  .landing-height {
    height: 300px;
  }

  .type-container,
  .click-container {
    width: 650px;
  }

  .type-container {
    height: 250px;
    font-size: 32px;
  }

  .click-container {
    font-size: 16px;
  }
}

@media screen and (min-width: 480px) and (max-width: 700px) {
  .landing-height {
    width: 85%;
    height: 275px;
  }

  .type-container,
  .click-container {
  }

  .type-container {
    font-size: 28px;
    padding-bottom: 30px;
  }

  .click-container {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .landing-height {
    width: 85%;
    height: 275px;
  }

  .type-container,
  .click-container {
  }

  .type-container {
    font-size: 23px;
    padding-bottom: 30px;
  }

  .click-container {
    font-size: 12px;
  }
}

.pointer {
  cursor: pointer;
}
