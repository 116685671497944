.header {
  font-size: 24px;
  position: fixed;
  top: 0;
  left: 0;
  width: 225px;
  z-index: 2;
  height: 100%;
}

.header-container {
  display: flex;
  flex-direction: column;
  padding-top: 45px;
  padding-left: 40px;
  text-align: center;
  height: 100%;
}

.hamburger-icon {
  height: 20px;
  width: 20px;
  right: 0px;
  position: absolute;
  padding-top: 6px;
  padding-right: 40px;

  display: none;
}

.padding_top_45 {
  padding-top: 45px;
}

.header-flex-split {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  height: calc(100% - 151px);
  padding-bottom: 40px;
}

.upper {
  display: flex;
  flex-direction: column;
  font-family: Overpass, sans-serif;
}

.name {
  text-decoration: none;
  color: black;

  font-size: 27px;
  font-weight: 700;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.home,
.about,
.contact,
.portfolio,
.time {
  text-align: left;
  text-decoration: none;
  color: black;

  font-size: 14px;
  padding-bottom: 14px;
}

.name,
.home,
.about,
.contact,
.portfolio {
  transition: 0.5s;
}

.name:hover,
.home:hover,
.about:hover,
.contact:hover,
.portfolio:hover {
  color: #888;
  transition: 0.3s;
  cursor: none;
}

.header-hr-container {
  padding-top: 18px;
  padding-bottom: 0px;
}

.header-hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #000;
  margin: 0;
  padding-bottom: 18px;
}

.active-link {
  font-weight: bold;
  transition: 0.3s;
}

.active-link:hover {
  color: black !important;
}

@media screen and (min-width: 1280px) and (max-width: 1405px) {
  .header {
    font-size: 20px;
    position: fixed;
    top: 0;
    left: 0;
    width: 200px;
    z-index: 2;
    height: 100%;
  }

  .header-container {
    padding-top: 49px;
  }

  .name {
    font-size: 24px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1280px) {
  .header {
    font-size: 18px;
    position: fixed;
    top: 0;
    left: 0;
    width: 175px;
    z-index: 2;
    height: 100%;
  }

  .header-container {
    padding-top: 55px;
  }

  .name {
    font-size: 19px;
  }

  .home,
  .about,
  .contact,
  .portfolio,
  .time {
    font-size: 12px;
  }
}

@media screen and (min-width: 0px) and (max-width: 1000px) {
  .header {
    font-size: 18px;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
  }

  .header-flex-split {
    padding-bottom: 0px;
  }

  .name {
    font-size: 28px;
    margin: 0 auto;
    width: fit-content;
  }

  .header-container {
    padding-right: 40px;
  }

  .hamburger-icon {
    display: block;
  }

  .time {
    display: none;
  }

  .home,
  .about,
  .contact,
  .portfolio {
    text-align: center;
    font-size: 16px;
  }

  .home-title-container {
    display: none;
  }
}
