.carousel {
}

.carousel-inner {
  height: 100%;
}

.carousel-item {
  height: 100%;
}

.carousel-image-container {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  height: inherit;
}

.carousel-image {
  height: auto;
  height: -webkit-fill-available;
  max-height: 100%;
  width: 100%;
}

.fa-chevron-left {
  stroke: black;
  stroke-opacity: 0.8;
  stroke-width: 10px;
  font-size: 20px;
}

.fa-chevron-right {
  stroke: black;
  stroke-opacity: 0.8;
  stroke-width: 10px;
  font-size: 20px;
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
  cursor: none !important;
}
