.about-title {
  font-family: Overpass, sans-serif;
  font-size: 28px;
  width: 100%;
  padding-bottom: 72px;
  text-align: justify;
}

@media screen and (min-width: 1340px) and (max-width: 1680px) {
  .about-title {
    font-size: 26px;
  }
}

@media screen and (min-width: 1190px) and (max-width: 1340px) {
  .about-title {
    font-size: 24px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1190px) {
  .about-title {
    font-size: 22px;
  }
}

@media screen and (min-width: 670px) and (max-width: 1000px) {
  .about-title {
    font-size: 20px;
  }
}

@media screen and (min-width: 570px) and (max-width: 670px) {
  .about-title {
    font-size: 20px;
  }
}

@media screen and (min-width: 470px) and (max-width: 570px) {
  .about-title {
    font-size: 18px;
  }
}

@media screen and (min-width: 370px) and (max-width: 470px) {
  .about-title {
    font-size: 18px;
  }
}

@media screen and (min-width: 0px) and (max-width: 370px) {
  .about-title {
    font-size: 16px;
  }
}
