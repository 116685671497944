@media screen and (min-width: 1001px) {
  html {
    cursor: none;
  }
}

@media screen and (min-width: 0px) and (max-width: 1000px) {
  .trail {
    display: none;
  }
}

.trail {
  position: absolute;
  height: 20px;
  pointer-events: none;
  overflow: visible;
}

.rainbow-black {
  z-index: 12;
}

.rainbow-red {
  z-index: 11;
}

.rainbow-orange {
  z-index: 10;
}

.rainbow-yellow-orange {
  z-index: 9;
}

.rainbow-yellow {
  z-index: 8;
}

.rainbow-green {
  z-index: 7;
}

.rainbow-blue-green {
  z-index: 6;
}

.rainbow-blue {
  z-index: 5;
}

.rainbow-purple {
  z-index: 4;
}

.rainbow-pink {
  z-index: 3;
}
