.experience-item-container {
  padding-bottom: 36px;
  font-size: 20px;
}

.experience-title {
  display: flex;
  justify-content: space-between;
}

.experience-subtitle {
  display: flex;
  justify-content: space-between;
}

.experience-title-large {
  font-size: 24px;
}

.experience-title-small {
  margin-top: auto;
}

.experience-title {
}

.experience-location {
}

.experience-body {
  padding-top: 12px;
  text-align: justify;
  font-size: 18px;
}

@media screen and (min-width: 1340px) and (max-width: 1680px) {
  .experience-title-large {
    font-size: 22px;
  }

  .experience-item-container {
    font-size: 18px;
  }

  .experience-body {
    font-size: 16px;
  }
}

@media screen and (min-width: 1190px) and (max-width: 1340px) {
  .experience-title-large {
    font-size: 18px;
  }

  .experience-item-container {
    font-size: 16px;
  }

  .experience-body {
    font-size: 14px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1190px) {
  .experience-title-large {
    font-size: 16px;
  }

  .experience-item-container {
    font-size: 14px;
  }

  .experience-body {
    font-size: 12px;
  }
}

@media screen and (min-width: 670px) and (max-width: 1000px) {
  .experience-title-large {
    font-size: 24px;
  }

  .experience-item-container {
    font-size: 20px;
  }

  .experience-body {
    font-size: 18px;
  }
}

@media screen and (min-width: 570px) and (max-width: 670px) {
  .experience-title-large {
    font-size: 20px;
  }

  .experience-item-container {
    font-size: 18px;
  }

  .experience-body {
    font-size: 16px;
  }
}

@media screen and (min-width: 470px) and (max-width: 570px) {
  .experience-title-large {
    font-size: 16px;
  }

  .experience-item-container {
    font-size: 14px;
  }

  .experience-body {
    font-size: 12px;
  }
}

@media screen and (min-width: 370px) and (max-width: 470px) {
  .experience-title-large {
    font-size: 14px;
  }

  .experience-item-container {
    font-size: 12px;
  }

  .experience-body {
    font-size: 10px;
  }
}

@media screen and (min-width: 0px) and (max-width: 370px) {
  .experience-title-large {
    font-size: 11px;
  }

  .experience-item-container {
    font-size: 10px;
  }

  .experience-body {
    font-size: 10px;
  }
}
