.contact-title-container {
  padding-bottom: 36px;
}

.contact-title {
  font-size: 24px;
}

.contact-row {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
}

.contact-item {
}

.contact-link {
  color: black;
  text-decoration: underline;
  transition: 0.5s;
}

.contact-link:hover {
  color: #888;
  transition: 0.3s;
  cursor: none;
}

@media screen and (min-width: 1340px) and (max-width: 1680px) {
  .coursework-title-left,
  .coursework-title-right,
  .organization-title-left,
  .organization-title-right,
  .education-title {
    font-size: 18px;
  }

  .contact-title {
    font-size: 22px;
  }
}

@media screen and (min-width: 1220px) and (max-width: 1340px) {
  .contact-row {
    font-size: 16px;
  }

  .contact-title {
    font-size: 20px;
  }
}

@media screen and (min-width: 1050px) and (max-width: 1220px) {
  .contact-row {
    font-size: 14px;
  }

  .contact-title {
    font-size: 18px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1050px) {
  .contact-row {
    font-size: 12px;
  }

  .contact-title {
    font-size: 16px;
  }
}

@media screen and (min-width: 740px) and (max-width: 1000px) {
  .contact-row {
    font-size: 20px;
  }

  .contact-title {
    font-size: 24px;
  }
}

@media screen and (min-width: 640px) and (max-width: 740px) {
  .contact-row {
    font-size: 18px;
  }

  .contact-title {
    font-size: 22px;
  }
}

@media screen and (min-width: 530px) and (max-width: 640px) {
  .contact-row {
    font-size: 16px;
  }

  .contact-title {
    font-size: 20px;
  }
}

@media screen and (min-width: 455px) and (max-width: 530px) {
  .contact-row {
    font-size: 14px;
  }

  .contact-title {
    font-size: 18px;
  }
}

@media screen and (min-width: 420px) and (max-width: 455px) {
  .contact-row {
    font-size: 12px;
  }

  .contact-title {
    font-size: 16px;
  }
}

@media screen and (min-width: 385px) and (max-width: 420px) {
  .contact-row {
    font-size: 11px;
  }

  .contact-title {
    font-size: 14px;
  }
}

@media screen and (min-width: 350px) and (max-width: 385px) {
  .contact-row {
    font-size: 10px;
  }

  .contact-title {
    font-size: 12px;
  }
}

@media screen and (min-width: 0px) and (max-width: 350px) {
  .contact-row {
    font-size: 9px;
  }

  .contact-title {
    font-size: 11px;
  }
}
