.hr-container {
  padding-top: 18px;
  padding-bottom: 0px;
}

.hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #000;
  margin: 0;
  padding-bottom: 18px;
}
